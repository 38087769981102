import ArrowPathMaterial from 'js/materials/ArrowPathMaterial';

export default class ArrowPath extends THREE.Mesh {
	constructor(points)
	{
		let texture_width = 32;
		let texture_height = 32;

		let max_arrow_count = texture_width * texture_height;

		let instanced_geometry = new THREE.InstancedBufferGeometry();
		let plane_geo = new THREE.PlaneBufferGeometry(1,1);


		let instance_ids = new THREE.InstancedBufferAttribute(new Float32Array(max_arrow_count), 1, false, 1);
		for(let i=0; i< max_arrow_count; i++)
		{
			instance_ids.setX(i, i);
		}


		instanced_geometry.addAttribute( 'position', 	new THREE.Float32BufferAttribute( plane_geo.getAttribute('position').array, 3 ) );
		instanced_geometry.addAttribute( 'uv', 	new THREE.Float32BufferAttribute( plane_geo.getAttribute('uv').array, 2 ) );
		instanced_geometry.addAttribute( 'id', instance_ids );
		instanced_geometry.index = plane_geo.index;



		let data = new Float32Array( 3 * texture_width*texture_height);
		let data_texture = new THREE.DataTexture( data, texture_width, texture_height, THREE.RGBFormat, THREE.FloatType );
		data_texture.wrapS = THREE.ClampToEdgeWrapping;
		data_texture.wrapT = THREE.ClampToEdgeWrapping;





		super(instanced_geometry, new ArrowPathMaterial())

		this.material.texture_path = data_texture;

		this.data_texture = data_texture;
	}

	set_path(points)
	{
		if(points.length > this.data_texture.image.width * this.data_texture.image.height)
		{
			console.error("Too many points to generate path. Needs texture redimension");
			return;
		}

		let distance = 0;
		for(let i=0; i< points.length; i++)
		{
			this.data_texture.image.data[i*3+0] = points[i].x;
			this.data_texture.image.data[i*3+1] = points[i].y;
			this.data_texture.image.data[i*3+2] = points[i].z;

			if(i > 0 && i < points.length-2)
				distance += points[i].distanceTo(points[i+1]);
		}
		this.data_texture.needsUpdate = true;

		let arrow_count = Math.max(Math.ceil(distance / 1), 1);
		this.material.point_count = points.length-3;
		this.material.arrow_count = arrow_count;
		this.material.path_length = distance;
		this.geometry.maxInstancedCount = arrow_count;

	}



	update()
	{
		this.material.update();
	}
}
