import BaseShaderMaterial from 'js/core/materials/BaseShaderMaterial'
import Time from 'js/core/Time';

import vert from 'js/shaders/arrow_path/arrow_path_vert';
import frag from 'js/shaders/arrow_path/arrow_path_frag';
import ResourceContainer from 'js/core/ResourceContainer';

export default class ArrowPathMaterial extends BaseShaderMaterial
{
	constructor()
	{
		super(vert, frag, {
			_TexturePath: {value: undefined},
			_PointCount: {value: 1},
			_ArrowCount: {value: 1},
			_ElapsedTime: {value: 0},
			_TextureWidth: {value: 1},
			_TextureHeight: {value: 1},
			_MainTex: {value: ResourceContainer.get_resource("path_chevrons")}
		});

		this.transparent = true;
		this.depthWrite = false;
		// this.blending = THREE.CustomBlending;
		// this.blendSrc = THREE.OneFactor;
		// this.blendDst = THREE.OneMinusSrcAlphaFactor;

		this.elapsed_time = 0;
		this.path_length = 1;
	}

	set point_count(value)
	{
		this.uniforms._PointCount.value = value;
	}

	set arrow_count(value)
	{
		this.uniforms._ArrowCount.value = value;
	}

	set texture_path(value)
	{
		this.uniforms._TexturePath.value = value;
		this.uniforms._TextureWidth.value = value.image.width;
		this.uniforms._TextureHeight.value = value.image.height;
	}


	update()
	{
		this.elapsed_time+= Time.delta_time * Math.max(1, (this.uniforms._PointCount.value)/this.path_length);
		console.log((Math.max(1, (this.uniforms._PointCount.value)/this.path_length) ));
		// this.elapsed_time+= Time.delta_time;
		this.uniforms._ElapsedTime.value = this.elapsed_time;


		// let sample_value = this.uniforms._ElapsedTime.value% this.uniforms._PointCount.value;
		// let y = Math.floor(sample_value/this.uniforms._TextureWidth.value);
		// let x = (sample_value%this.uniforms._TextureWidth.value) /this.uniforms._TextureWidth.value;
		// console.log(x,y);
	}
	
}