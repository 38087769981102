export default class ViewBaseState
{
	constructor()
	{

	}

	on_enter(camera_view_cube)
	{

	}
	on_exit(camera_view_cube)
	{
		
	}

	update(camera_view_cube)
	{
		
	}
}
