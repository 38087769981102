import CameraManager from 'js/core/CameraManager';
import SceneManager from 'js/core/SceneManager';
import Screen from 'js/core/Screen';

import ClearDepthNormalMaterial from 'js/core/materials/ClearDepthNormalMaterial';
import DepthNormalMaterial from 'js/core/materials/DepthNormalMaterial';

export default class DepthAndNormalsRenderer
{
	constructor()
	{
    this.RT = new THREE.WebGLRenderTarget(Screen.width, Screen.height);
    
    this.clear_depth_normal_mat = new ClearDepthNormalMaterial(1, new THREE.Vector3(0,0,1));
    this.depth_normal_material = new DepthNormalMaterial();
	}


	render(graphics)
	{
		this.__resize_RT_if_necessary();


    graphics.clear(this.RT, undefined, true,true)

    graphics.blit_clear_with_material(this.RT, this.clear_depth_normal_mat);

    this.depth_normal_material.far_plane = CameraManager.current.far;

    graphics.render(undefined, undefined, this.RT, this.depth_normal_material);

	}

  __resize_RT_if_necessary()
  {
    if(this.RT.width !== Screen.width || this.RT.height !== Screen.height)
    {
      this.RT.setSize(Screen.width, Screen.height);
    }
  }

	get render_target()
	{
		return this.RT;
	}

}
