import BaseShaderMaterial from 'js/core/materials/BaseShaderMaterial'

import vert from 'js/shaders/water/water_vert';
import frag from 'js/shaders/water/water_frag';

export default class WaterMat extends BaseShaderMaterial
{
  constructor()
  {
    super(vert, frag, {
      _Cube : {value: undefined}
    });
  }

  set_cube(cube)
  {
    this.uniforms._Cube.value = cube;
  }
}
