import Graphics from 'js/core/Graphics';
import RenderLoop from 'js/core/RenderLoop';
import Configuration from 'js/singletons/Configuration';

import EventManager from 'js/core/EventManager';
import Debug from 'js/core/Debug';
import Input from 'js/core/Input';

import CameraManager from 'js/core/CameraManager';
import SceneManager from 'js/core/SceneManager';

//APP
import OHZIExampleApplication from './OHZIExampleApplication';

//SERVICES
import ModelUtilities from 'js/core/utilities/ModelUtilities';
//
import ResourceContainer from 'js/core/ResourceContainer';

module.exports = (parameters) => {

  let container = document.getElementById(parameters.main_container);
  

  Graphics.append_canvas(container);

  Input.init(Graphics.dom_element);
  Debug.init(Graphics);
  const application = new OHZIExampleApplication(Graphics);

  const render_loop = new RenderLoop(application, Graphics);
  
  Graphics.on_resize();


  function ViewApi() {
    return {
      
      scene: SceneManager.current,
      camera: CameraManager.current,

      model_utilities: ModelUtilities,

      take_screenshot: () => {
        Graphics.take_screenshot()
      },

      //#######################################
      //#######################################

      register_event: (name, callback) =>{
        EventManager.on(name, callback);
      },
     
      config: Configuration,

      draw_debug_axis: () =>{
        Debug.draw_axis();
      },
      update: (val) => {
        application.update_texture(val);
      },
      
      set_resource: (name, resource)=>{
        ResourceContainer.set_resource(name, resource);
      },

      resize_canvas: ()=>{
        Graphics.on_resize();

      },

      start: ()=>{
        render_loop.start();
      },
      
      dispose: () =>{
        render_loop.stop();
        Graphics._renderer.dispose();
        map.dispose();
      },
      
      resource_loading_completed : () => {
        application.resources_fully_loaded()
      },
      version: "0.0.0",
    };
  }

  module.exports = ViewApi;

  return ViewApi();
};
