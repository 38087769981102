import BaseApplication from 'js/core/BaseApplication';
import CameraManager from 'js/core/CameraManager';
import SceneManager from 'js/core/SceneManager';
import Debug from 'js/core/Debug';
import Screen from 'js/core/Screen';
import CameraController from 'js/components/CameraController/CameraController';
import CameraUtilities from 'js/core/utilities/CameraUtilities';
import PerspectiveCamera from 'js/core/PerspectiveCamera';
import OrthographicCamera from 'js/core/OrthographicCamera';


import Input from 'js/core/Input';

import Grid from 'js/core/components/Grid';
import Cube from 'js/core/primitives/Cube';
import Sphere from 'js/core/primitives/Sphere';
import Arrow from 'js/core/primitives/Arrow';
import ObjectAxis from 'js/core/components/ObjectAxis';

import ResourceContainer from 'js/core/ResourceContainer';
import ModelUtilities from 'js/core/utilities/ModelUtilities';
import NormalAORender from 'js/core/render_mode/NormalAORender';
import NormalRender from 'js/core/render_mode/NormalRender';
import Graphics from 'js/core/Graphics';

import Time from 'js/core/Time';
import ArrowPath from 'js/components/ArrowPath';
import CameraViewCube from 'js/components/camera_view_cube/CameraViewCube';
import LayeredRender from 'js/components/LayeredRender';
import MultiLineText2D from 'js/core/components/MultiLineText2D';
import Line from 'js/core/components/Line';
import ArrayUtilities from 'js/core/utilities/ArrayUtilities';
import Text2D from 'js/core/components/Text2D';
import MathUtilities from 'js/core/utilities/MathUtilities';

import WaterMat from 'js/materials/WaterMat';
export default class OHZIExampleApplication extends BaseApplication
{
  constructor(renderer)
  {
    super();
    this.renderer = renderer;

    this.camera_controller = new CameraController();
    this.normal_render_mode = new NormalRender();

    this.view_cube_layer = undefined;


    this.anim_delta = 0;

    this.meshes = [];
  }



  start()
  {
    this.__init_camera();
    this.__init_camera_controller();
    this.__init_viewcube()
    // Debug.draw_axis();

    this.reference_position = new THREE.Vector3();

    let grid = new Grid();
    // SceneManager.current.add(grid);


		let scene = ResourceContainer.get_resource("metaball").scene;

		scene.children.sort((a, b)=>{
			return parseInt(a.name.split('_')[1])-parseInt(b.name.split('_')[1])}
		)

		this.frame_count = scene.children.length;
    // console.log(scene.children.length)

    this.water_material = new WaterMat();

    scene.traverse((mesh)=>{
      if(mesh.geometry)
      {
        mesh.material = this.water_material;
        // mesh.material = new THREE.MeshMatcapMaterial();

        // mesh.material.matcap = ResourceContainer.get_resource("matcap");

      }
    })
		for(let i=0; i< scene.children.length; i++)
		{
			let mesh = scene.children[i];
			mesh.scale.multiplyScalar(0.01);
			mesh.visible = false;
			// mesh.material = new THREE.MeshMatcapMaterial();
      // mesh.material.matcap = ResourceContainer.get_resource("matcap");
			this.meshes.push(mesh);
			// console.log(mesh.name)
      mesh.remove(mesh.getObjectByName("Sphere"));
		}


		SceneManager.current.add(scene);


    let light = new THREE.DirectionalLight("#8096A6", 0.5);
    light.position.y = 1;
    light.position.x = 0.5;
    light.position.z = 0.5;
    SceneManager.current.add(light)

    let ambient_light = new THREE.AmbientLight("#47B8FF" );
    SceneManager.current.add(ambient_light)

    let floor = new Cube();
    floor.material = new THREE.MeshStandardMaterial({color: 0xffffff})
    floor.scale.set(5.4,0.7,4.5);
    floor.position.y = 0.2225;
    // SceneManager.current.add(floor);



    // let cubeCamera = new THREE.CubeCamera( 1, 1000, 256, { format: THREE.RGBAFormat, magFilter: THREE.LinearFilter, minFilter: THREE.LinearFilter } );
    // cubeCamera.renderTarget.texture.encoding = THREE.sRGBEncoding;
    // this.cubeCamera = cubeCamera;
    // let cubemap_scene = new THREE.Scene();
    // cubemap_scene.add(floor);
    // cubemap_scene.add(light.clone());
    // cubemap_scene.add(ambient_light.clone());
    this.cubemap = new THREE.CubeTextureLoader()
    .setPath( 'textures/cube/' )
    .load( [
      'px.png',
      'nx.png',
      'py.png',
      'ny.png',
      'pz.png',
      'nz.png'
    ] , (cubeTexture)=>{
        // cubeTexture.encoding = THREE.sRGBEncoding;
        // floor.position.y -= 3;
        // cubemap_scene.background  = cubeTexture;
        // // cubemap_scene.environment = cubeTexture;

        // cubeCamera.update( Graphics._renderer, cubemap_scene );
        // SceneManager.current.add(floor);
        // floor.position.y += 3;

        // light_probe.copy( THREE.LightProbeGenerator.fromCubeRenderTarget( Graphics._renderer, cubeCamera.renderTarget ) );
    });

    // SceneManager.current.background = this.cubemap

  }

  hide_meshes()
  {
  	for(let i=0; i< this.meshes.length; i++)
  	{
  		this.meshes[i].visible = false;
  	}
  }



  resources_fully_loaded() {

  }

  update()
  {
    this.camera_controller.update();
    this.camera_view_cube.update();


    if(this.meshes.length > 0 )
    {
    	this.anim_delta += Time.delta_time;

    	let sequence_i = Math.floor(((this.anim_delta % (this.frame_count / 30)) / (this.frame_count / 30)) * this.frame_count);

	    // console.log(this.anim_delta, sequence_i);
      sequence_i = THREE.Math.clamp(sequence_i, 0, 126);
	    this.hide_meshes();
      if(this.anim_delta < (this.frame_count / 30))
      {
        this.meshes[sequence_i].visible = true;
      }

      this.water_material.set_cube(this.cubemap);

      if(this.anim_delta > (this.frame_count / 30) + 1)
      {
        this.anim_delta = 0;
      }
    }
    this.camera_controller.set_rotation_delta(Time.delta_time*10);
  }





  __init_camera()
  {
    let camera = new PerspectiveCamera(60, Screen.aspect_ratio, 0.1, 200);
    // let camera = new OrthographicCamera( Screen.width / - 2, Screen.width / 2, Screen.height / 2, Screen.height / - 2, 0.1, 200 );
    // camera.zoom = 40;
    camera.updateProjectionMatrix();
    camera.position.z = 10;

    camera.clear_color.copy(new THREE.Color("#000000"));
    camera.clear_alpha = 1;
    CameraManager.current = camera;
  }

  __init_camera_controller()
  {
    this.camera_controller.set_camera(CameraManager.current);
    this.camera_controller.set_debug_mode();
    // this.camera_controller.set_idle();
    this.camera_controller.min_zoom = 1;
    this.camera_controller.max_zoom = 200;
    this.camera_controller.reference_zoom = 10;
    // this.camera_controller.set_tilt(90);
    //this.camera_controller.reference_position.set(0,100,0);
  }

  __init_viewcube()
  {
    this.view_cube_layer = {
    	camera: new OrthographicCamera(-1.1, 1.1, 1.1, -1.1, 0.1, 200),
    	scene: new THREE.Scene(),
    	viewport: new THREE.Vector4(Screen.width-256, Screen.height-256, 256, 256)
    }

    this.layered_render = new LayeredRender([this.view_cube_layer])
    Graphics.set_state(this.layered_render);


		let bounding_box = new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(), new THREE.Vector3(1,10,5));

    this.camera_view_cube = new CameraViewCube( this.camera_controller,
    																						0,
  																							this.view_cube_layer.viewport,
  																							this.view_cube_layer.camera,
  																							bounding_box);
    this.camera_view_cube.scale.set(0.5, 0.5, 0.5)
    this.view_cube_layer.scene.add(this.camera_view_cube);
  }
}
